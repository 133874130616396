import React from "react"
import {graphql} from 'gatsby'
import Layout from "../../../components/layout"
import styled from "@emotion/styled"
import Card from "../../../components/molecules/Card/Card"
import Content from "../../../components/particles/Content"
import Breadcrumbs from "../../../components/organisms/breadcrumbs/Breadcrumbs"
import Banner from "../../../components/organisms/banner/Banner"
import theme from "../../../components/particles/Theme"
import EmergencyBanner from "../../../components/organisms/emergencyBanner/EmergencyBanner"

const StyledProductCards = styled.div`
  margin: 5rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;

  @media (max-width: ${theme.breakpoints[1]}) {
    grid-template-columns: 1fr;
  }
`

const index = ({ data }) => {
  const residentialImages =
    data.wp.productSearch.productSearchOptions.residentialOptions

  return (
    <Layout title="Product Search - Residential">
      {/* <EmergencyBanner /> */}
      <Banner title="Product Search - Residential" />
      <Breadcrumbs />
      <Content>
        <StyledProductCards>
          <Card
            title="Interior"
            link="/products/residential/interior/"
            backgroundImage={residentialImages.interiorImage || undefined}
          />
          <Card
            title="Exterior"
            link="/products/residential/exterior/"
            backgroundImage={residentialImages.exteriorImage || undefined}
          />
        </StyledProductCards>
      </Content>
    </Layout>
  )
}

export const query = graphql`query ProductSearchOptionsReidential {
  wp {
    productSearch {
      productSearchOptions {
        residentialOptions {
          exteriorImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            sourceUrl
          }
          interiorImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            sourceUrl
          }
        }
      }
    }
  }
}
`

export default index
